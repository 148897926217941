import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  message: string;
  submitted = false;
  hide = true;
  emailForm: FormGroup;
  constructor(public authService: AuthService, public fb: FormBuilder, private router: Router,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }
  ngOnInit() {
    
  }
  get f() { return this.emailForm.controls; }
  checkEmail() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    } else {
      this.spinner.show();
      this.submitted = false;
      this.message = "";
      this.authService.forgetPassword(this.emailForm.value).subscribe((res: any) => {
        
        if (res.status == '200') {
         //Redirect to reset password page cardinalii.com/resetpassword/{{email}}
         this.spinner.hide();
            this.toastr.success(
              '<span class=" tim-icons icon-bell-55"></span>กรุณาตรวจสอบที่อีเมลของคุณเพื่อทำการรีเซ็ตรหัสผ่าน',
              "",
              {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-success alert-with-icon",
                positionClass: "toast-top-right"
              }
            );
        } else {
          this.spinner.hide();
          this.toastr.error(
            '<span class=" tim-icons icon-bell-55"></span>' + res.description,
            "",
            {
              timeOut: 8000,
              enableHtml: true,
              closeButton: true,
              toastClass: "alert alert-danger alert-with-icon",
              positionClass: "toast-top-right"
            }
          );
        }

      })
    }
  }

}
