import { LoginComponent } from './../pages/login/login.component';

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { User } from './user';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthService {
  //apiURL="http://api.tdeca.com/api/auth/";
  //apiURL="http://auto-api.tdeca.com/api";
  public redirectUrl: string; 
  public tokenKey: string;
  public messageError:string="";
  constructor(public jwtHelper: JwtHelperService,private http:HttpClient,private router : Router) {}
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  userAuthentication(user: User) {
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    return this.http.post<User>(environment.apiURL+'auth', user)
      
  }
  getShopname(){
    return localStorage.getItem('shop_name');
  }
  getToken() {
    return localStorage.getItem('access_token');
  }
  getUser() {
    return localStorage.getItem('access_token');
  }
  getUserLevel() {
    return localStorage.getItem('user_level');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      this.router.navigate(['login']);
    }
  }
  
  forgetPassword(data){
    data.url=environment.picURL;
    return this.http.post(environment.apiURL+'forget-password',data);
  }
  

  // Error 
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}