<div class="row">
  <div class="col-3"></div>
  <div class="col-6 mt-5">
    <h3>โปรดอ่าน</h3>
    <p>1. หนึ่งหมายเลขจะสุ่มเปิดกล่องได้หนึ่งครั้งเท่านั้น หากเปิดแล้ว บริษัทไม่สามารถแก้ไขให้กลับมาเปิดใหม่ได้อีกครั้ง</p>
    <p>2. ลูกค้าต้องบันทึกหน้าจอหรือโหลดรูปหลังจากเปิดแล้ว เพื่อแสดงหลักฐาน</p>
    <p>3. สินค้าหรือบริการที่เปิดได้จากกล่องสุ่ม ไม่สามารถแลกเป็นเงินได้</p>
    <label class=" form-check-label"><input class=""
      type="checkbox" [(ngModel)]="checkCondition"> ฉันได้อ่านและรับทราบเงื่อนไขแล้ว</label>
    <h5 class="mt-3">***กรุณากดยอมรับเพื่อเปิดกล่อง</h5>
  </div>
  <div class="col-3"></div>
</div>
<div class="row" *ngIf="checkCondition">
  <div class="col-2"></div>
  <div class="col-8 mt-5">
    <h2>Cardinal Mystery Box</h2>
    <h3>มาร่วมลุ้นกันว่าคุณจะได้อะไรในกล่องสุ่ม!</h3>
    <div class=" card mt-5">
      <div class="card-header">
        <h4 class="card-title">กรอกหมายเลขกล่องสุ่มและกดเปิดเพื่อดูสินค้าที่ได้</h4>
      </div>
      <div class="card-body">
        <form autocomplete="off" [formGroup]="searchForm" (ngSubmit)="onSearch()">
          <div class=" row">
            <div class=" col-md-12">
              <div class="form-group">
                <label>หมายเลขกล่องสุ่ม<span class="span">*</span></label>
                <input class="form-control form-control-lg"
                  [ngClass]="{ 'is-invalid': searched && s.booking_no.errors }" type="text" formControlName="booking_no"
                  required />
                <div *ngIf="searched && s.booking_no.errors" class="invalid-feedback">
                  <div *ngIf="s.booking_no.errors.required">This filed is required!</div>
                </div>
              </div>
              <!--<button type="button" class="btn btn-success mx-auto d-block" (click)="isCollapsedForm = !isCollapsedForm"
                  [attr.aria-expanded]="!isCollapsedForm" aria-controls="collapseBasic">ค้นหา
                </button>-->
              <button class="btn btn-gold mx-auto" type="submit">กดเปิด</button>
            </div>
          </div>
        </form>


      </div>
    </div>
  </div>
  <div class="col-2"></div>
</div>
<!-- <ng-template #unboxModal let-modal aria-hidden="true">
  <div class="modal-body container bg-tranparent text-white">
    <video id="video_background" preload="auto" volume="5" autoplay="1" width="100%" height="100%" (ended)="vidEnded()">
      <source src="assets/videos/MYSTERYBOX.mp4" type="video/mp4" />
    </video>
  </div>
</ng-template> -->
<ng-template #rewardModal let-modal>
  <div class="modal-header bg-cardinal">
    <!-- <button type="button" class="close text-white" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body container bg-cardinal text-white">
   
    <div id="image_background">
      <img src="{{imagePath}}" class="img-fluid mt-3 mb-3" alt="">
      <p style="font-size: 1.2rem;color:#ffffff;">หมายเลขการจอง {{booking_no}}</p>
      <p style="color:#ffffff;">*เงื่อนไขเป็นไปตามบริษัทกำหนด<br>โปรดอ่านเพิ่มเติมได้ที่
        <a href="https://www.cardinalfilm.com/mysterybox" style="color:#948159;"
          target="_blank">www.cardinalfilm.com/mysterybox</a>
      </p>
    </div>

  </div>
  <div class="modal-footer bg-cardinal">
    <button type="button" class="btn btn-gold" (click)="modal.close('Close click')">ตกลง</button>
  </div>
</ng-template>
