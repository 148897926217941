import { Component, OnInit, HostListener } from "@angular/core";
import { AuthService } from './../../auth/auth.service';
import PerfectScrollbar from "perfect-scrollbar";
@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {
  public sidebarColor: string = "#e9d4b3";

  constructor(public authService: AuthService) {}
  
  /*changeSidebarColor(color){
    var sidebar = document.getElementsByClassName('sidebar')[0];
    var mainPanel = document.getElementsByClassName('main-panel')[0];

    this.sidebarColor = "#e9d4b3";

    if(sidebar != undefined){
        sidebar.setAttribute('data',this.sidebarColor);
    }
    if(mainPanel != undefined){
        mainPanel.setAttribute('data',this.sidebarColor);
    }
  }
  changeDashboardColor(color){
    var body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
        body.classList.add(color);
    }
    else if(body.classList.contains('white-content')) {
      body.classList.remove('white-content');
    }
  }*/
  @HostListener("window:scroll", ["$event"])
  showNavbarButton = () => {
    var mainPanel: any = document.getElementsByClassName("main-panel")[0];
    

    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      mainPanel.scrollTop > 50
    ) {
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      mainPanel.scrollTop <= 50
    ) {
    }
  };
  ngOnInit() {//this.changeSidebarColor("green");
  //console.log(navigator.platform.indexOf("Win"));
  var body = document.getElementsByTagName('body')[0];
  body.classList.add("#e9d4b3");

  var mainPanel: any = document.getElementsByClassName("main-panel")[0];
    var sidebar: any = document.getElementsByClassName("sidebar-wrapper")[0];

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      var ps = new PerfectScrollbar(mainPanel);
      ps = new PerfectScrollbar(sidebar);
      var tables: any = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }else{
      console.log(navigator.platform.indexOf("Win"));
    }
    this.showNavbarButton();
}
//logout
logout() {
  this.authService.doLogout()
}
}
