import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";


import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { LoginComponent } from './pages/login/login.component';

import { NgbPaginationModule, NgbAlertModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddAgentComponent } from './pages/admin/add-agent/add-agent.component';
import { AddMemberComponent } from './pages/admin/add-member/add-member.component';
import { AddCarComponent } from './pages/admin/add-car/add-car.component';
import { MyAgentComponent } from './pages/admin/my-agent/my-agent.component';
import { MyMemberComponent } from './pages/admin/my-member/my-member.component';
import { MyCarComponent } from './pages/admin/my-car/my-car.component';
import { MyHistoryComponent } from './pages/admin/my-history/my-history.component';
import { ProfileComponent } from './pages/admin/profile/profile.component';
import { CarSettingComponent } from './pages/admin/setting/car/car.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
// Datepicker module
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './service/profile.service';
import { AgentService } from './service/agent.service';
import { MemberService } from './service/member.service';
import { CarService } from './service/car.service';
import { HistoryService } from './service/history.service';
import { InstallComponent } from './pages/admin/install/install.component';
//Modal
import { ModalModule } from 'ngx-bootstrap/modal';
//Pagination
import { PaginationModule } from 'ngx-bootstrap/pagination';

//Removing hash (#) on URL
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { RegisterComponent } from './pages/register/register.component';

//Google Recaptcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { RedeemComponent } from './pages/admin/redeem/redeem.component'

//Notification
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ApproveRedeemComponent } from './pages/admin/approve-redeem/approve-redeem.component';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

//Datatable
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CarComponent } from './pages/admin/report/car/car.component';
import { ReportInstallComponent } from './pages/admin/report/install/install.component';

//Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InstallerComponent } from './pages/admin/setting/installer/installer.component';
import { InstallcenterComponent } from './pages/admin/setting/installcenter/installcenter.component';
import { CalendarComponent } from './pages/admin/calendar/calendar.component';

//Scrollbar
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
//Event calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";

//Search select
import { NgSelectModule } from '@ng-select/ng-select';

import { DatePipe } from '@angular/common';
import { AmountComponent } from './pages/admin/dashboard/installation/amount/amount.component';
import { PerformanceComponent } from './pages/admin/dashboard/installation/performance/performance.component';
import { QualityComponent } from './pages/admin/dashboard/installation/quality/quality.component';
import { ChartComponent } from './pages/admin/dashboard/chart/chart.component';

//Spinning Wheel
import { NgxWheelModule } from 'ngx-wheel';
import { LuckyWheelComponent } from './pages/lucky-wheel/lucky-wheel.component';
import { LuckyWheelCheckComponent } from './pages/lucky-wheel/lucky-wheel-check/lucky-wheel-check.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';

import { UserIdleModule } from 'angular-user-idle';
@NgModule({
  imports: [
    BrowserModule,
    NgbPaginationModule,
    PaginationModule.forRoot(),
    NgbAlertModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    MatFormFieldModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDatepickerModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    RxReactiveFormsModule,
    SnotifyModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      positionClass: "toast-top-right",
    }),
    ToastContainerModule,
    NgxDatatableModule,
    PerfectScrollbarModule,//scrollbar
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxSpinnerModule,
    NgSelectModule,
    NgxWheelModule,
    UserIdleModule.forRoot({idle: 3600, timeout: 5, ping: 30})
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, LoginComponent, AddAgentComponent, AddMemberComponent, AddCarComponent, MyAgentComponent, MyMemberComponent, MyCarComponent, MyHistoryComponent, ProfileComponent, InstallComponent, RegisterComponent, RedeemComponent, ApproveRedeemComponent, CarComponent, InstallerComponent, InstallcenterComponent,CarSettingComponent,ReportInstallComponent, CalendarComponent, AmountComponent, PerformanceComponent, QualityComponent, ChartComponent, LuckyWheelComponent, LuckyWheelCheckComponent, ForgotPasswordComponent,],
  providers: [DatePipe,{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, AuthGuardService, AuthService, JwtHelperService, ProfileService, AgentService, MemberService, CarService, HistoryService, Location, { provide: LocationStrategy, useClass: PathLocationStrategy }, { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,{provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})

export class AppModule { }
