<div class="full-page register-page">
  <div class=" content">
    <div class=" container">
      <div class=" row">
        <div class=" col-lg-12 col-md-12 ml-auto mr-auto mt-2">
          <form class=" form" [formGroup]="signinForm" (ngSubmit)="onSubmit()">
            <div class=" card card-login">
              <div class=" card-header text-center mt-4">
                <img alt="aututuxedo logo" class="card-image" src="assets/imgs/logo.png">
                <h3 class=" card-title mt-3">สร้างบัญชีสมาชิก</h3>
              </div>
              <div class=" card-body">
                <div *ngIf="message" class="alert alert-danger">{{message}}</div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-single-02"></i><span
                            class="span">*</span>
                        </div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="ชื่อ"
                        formControlName="firstname" type="text" required>
                      <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required">กรุณากรอกชื่อ</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-single-02"></i><span
                            class="span">*</span>
                        </div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="นามสกุล"
                        formControlName="lastname" type="text" required>
                      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.lastname.errors.required">กรุณากรอกนามสกุล</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-mobile"></i><span class="span">*</span>
                        </div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" placeholder="เบอร์มือถือ"
                        formControlName="tel" type="tel" required>
                      <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                        <div *ngIf="f.tel.errors.required">กรุณากรอกเบอร์มือถือ</div>
                        <div *ngIf="f.tel.errors.minLength">เบอร์มือถือจะต้องมี 10 หลัก</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-email-85"></i><span
                            class="span">*</span>
                        </div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="อีเมลล์"
                        formControlName="email" type="email" required>
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">กรุณากรอกอีเมลล์</div>
                        <div *ngIf="f.email.errors.email">ข้อมูลไม่ตรงตามรูปแบบมาตรฐานอีเมลล์</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <div class="input-group">
                        <div class=" input-group-prepend">
                          <div class=" input-group-text"><i class=" tim-icons icon-lock-circle"></i><span
                              class="span">*</span></div>
                        </div>
                        <input class="form-control form-control-lg"
                          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="รหัสผ่าน"
                          type="password" value="" formControlName="password" required />
                        <!--<div><small><span class="text-warning">ขั้นต่ำ 6 ตัวอักษร</span></small></div>-->

                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">กรุณากรอกรหัสผ่าน</div>
                          <div *ngIf="f.password.errors.minlength">รหัสผ่านจะต้องมีขั้นต่ำ 6 ตัวอักษร</div>
                        </div>
                      </div>
                      <small><span class="text-notice">ขั้นต่ำ 6 ตัวอักษร</span></small>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-lock-circle"></i><span
                            class="span">*</span></div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" placeholder="ยืนยันรหัสผ่าน"
                        type="password" value="" formControlName="confirmPassword" required />

                      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">กรุณากรอกยืนยันรหัสผ่าน</div>
                        <div *ngIf="f.confirmPassword.errors.minlength">รหัสผ่านจะต้องมีขั้นต่ำ 6 ตัวอักษร</div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch">รหัสผ่านไม่ตรงกัน</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class=" tim-icons icon-square-pin"></i><span
                            class="span">*</span>
                        </div>
                      </div>
                      <select class="form-control custom-select-lg" placeholder="จังหวัดที่พักอาศัย"
                        [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" required>
                        <option value="" selected disabled>จังหวัดที่พักอาศัย</option>
                        <option *ngFor="let data of dataProvince" value="{{data.name}}">{{data.name}}</option>
                      </select>
                      <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">กรุณาเลือกจังหวัดที่พักอาศัย</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class="fas fa-car"></i><span class="span">*</span></div>
                      </div>
                      <input class=" form-control form-control-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.carHave.errors }" placeholder="จำนวนรถที่มีอยู่"
                        formControlName="carHave" type="number" required>
                      <div *ngIf="submitted && f.carHave.errors" class="invalid-feedback">
                        <div *ngIf="f.carHave.errors.required">กรุณากรอกจำนวนรถที่มีอยู่</div>
                        <div *ngIf="f.carHave.errors.min">กรุณากรอกจำนวนรถอย่างน้อย 1 คัน</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class="fas fa-car"></i></div>
                      </div>
                      <select #carBrandMain (change)="carbrandOnChange(carBrandMain.value,'carBrandMain')"
                        class="form-control custom-select-lg" formControlName="carBrandMain">
                        <option value="" selected disabled>ยี่ห้อรถหลักที่ใช้</option>
                        <option *ngFor="let data of dataCarbrand" value="{{data.name}}">{{data.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class="fas fa-car"></i></div>
                      </div>
                      <select class="form-control  custom-select-lg " formControlName="carModelMain">
                        <option value="" selected disabled>รุ่นรถหลักที่ใช้</option>
                        <option *ngFor="let data of dataCarmodelMain" value="{{data.name}}">{{data.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class="fas fa-car"></i></div>
                      </div>
                      <select #carBrandSecond (change)="carbrandOnChange(carBrandSecond.value,'carModelSecond')"
                        class="form-control custom-select-lg" formControlName="carBrandSecond">
                        <option value="" selected disabled>ยี่ห้อรถคันที่ 2 ที่ใช้</option>
                        <option *ngFor="let data of dataCarbrand" value="{{data.name}}">{{data.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group">
                      <div class=" input-group-prepend">
                        <div class=" input-group-text"><i class="fas fa-car"></i></div>
                      </div>
                      <select class="form-control  custom-select-lg " formControlName="carModelSecond">
                        <option value="" selected disabled>รุ่นรถคันที่ 2 ที่ใช้</option>
                        <option *ngFor="let data of dataCarmodelSecond">{{data.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class=" row">
                  <div class=" col-sm-6 col-form-label">
                    <p>คุณเคยติดฟิล์มคาร์ดินอลหรือไม่</p>
                  </div>
                  <div class=" col-sm-6 checkbox-radios mt--3">
                    <div class=" form-check form-check-radio">
                      <label class=" form-check-label">
                        <input checked="checked" class=" form-check-input" name="usedToFilm" type="radio" value="1"
                          formControlName="usedToFilm">
                        <span class=" form-check-sign"></span>
                        ใช่
                      </label>
                      <label class=" form-check-label ml-3">
                        <input class=" form-check-input" formControlName="usedToFilm" name="usedToFilm" type="radio"
                          value="0">
                        <span class=" form-check-sign"></span>
                        ไม่
                      </label>
                    </div>
                  </div>
                </div>
                <div class=" row">
                  <div class=" col-sm-6 col-form-label">
                    <p>คุณสนใจต่อประกันรถยนต์</p>
                  </div>
                  <div class=" col-sm-6 checkbox-radios mt--3">
                    <div class=" form-check form-check-radio">
                      <label class=" form-check-label">
                        <input checked="checked" class=" form-check-input" name="demand" type="radio" value="1"
                          formControlName="demand">
                        <span class=" form-check-sign"></span>
                        ใช่
                      </label>
                      <label class=" form-check-label ml-3">
                        <input class=" form-check-input" formControlName="demand" name="demand" type="radio"
                          value="0">
                        <span class=" form-check-sign"></span>
                        ไม่
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class=" col-md-12">
                    <div class=" form-group">
                      <label>วันหมดอายุประกันรถยนต์หลักของท่าน</label>
                      <div class="input-group mb-3">
                        <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="insuranceEndDate" ngbDatepicker
                          #b="ngbDatepicker" formControlName="insurance_end_date" (click)="b.toggle()"/>
    
                        <div class="input-group-append">
    
                          <button class="btn btn-md btn-default btn-simple" (click)="b.toggle()" type="button"><i
                              class="far fa-calendar-alt"></i></button>
                        </div>
                      </div>
    
                      <!--<input formControlName="date" type="text" bsDatepicker class="form-control" />
                    <input formControlName="date" type="text" bsDatepicker class="form-control" />-->
                    </div>
                  </div>
                </div>
                <div class=" row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <textarea class="form-control" rows="4" cols="50"
                        placeholder="กรุณาใส่หมายเลขใบรับประกัน ศูนย์ที่ติดตั้งและวันที่ติดตั้ง(หากมี) หรือพิมพ์คำถามเพิ่มเติม"
                        formControlName="others"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class=" form-check mt-3 mr-2"><label class=" form-check-label"><input class=" form-check-input"
                        type="checkbox" formControlName="consent" required><span
                        class=" form-check-sign"></span>ยินยอมให้บริษัท อินนิแฟตโต้ จำกัด จัดเก็บ
                      รวบรวมข้อมูลข้างต้น รวมทั้งข้อมูลส่วนบุคคล ไว้เพื่อวัตถุประสงค์ทางการตลาด
                      การติดต่อเพื่อขายบริการหลังการขายและการนำเสนอสิทธิพิเศษ ผลิตภัณฑ์หรือบริการของบริษัท อินนิแฟตโต้
                      จำกัดหรือบริษัทในเครือ</label></div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-12">
                    <div class="input-group">
                      <!-- 
                        the SITE_KEY is the sitekey from your admin console.
                        use form control for validation
                      -->
                      <re-captcha (resolved)="resolved($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                        formControlName="recaptchaReactive" class="g-recaptcha"
                        siteKey="6Lelz6IZAAAAACvnJPmqx4f6kxbfRXJ1IRIbNwva" required>
                      </re-captcha>
                      <div *ngIf="submitted && f.recaptchaReactive.errors" class="invalid-feedback">
                        <div *ngIf="f.recaptchaReactive.errors.required">กรุณายืนยัน CAPTCHA!</div>
                      </div>
                    </div>

                  </div>
                </div>

                <p>เป็นสมาชิกอยู่แล้ว? <a href="login" class="text-register">เข้าสู่ระบบทางนี้</a></p>
                <button class="btn btn-gold animation-on-hover mt-4 ml-auto mr-auto mb-3" type="submit">ยืนยัน</button>
              </div>

              <div class=" card-footer">
                <!--<a class=" btn btn-primary btn-lg btn-block mb-3" href="#pablo">Login</a>-->

                <!--<div class=" pull-left">
                    <h6><a class=" link footer-link" href="javascript:void(0)"> Create Account </a></h6>
                  </div>
                  <div class=" pull-right">
                    <h6><a class=" link footer-link" href="javascript:void(0)"> Need Help? </a></h6>
                  </div>-->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
