<div class="sidebar-wrapper">
  <div class="logo">
    <img mat-card-image src="assets/imgs/logo.png" alt="Logo">

    <!--<a [routerLink]="['/profile']" class="simple-text logo-normal">
    </a>-->
  </div>
  <ul class="nav">
    <li *ngFor="let menuitem of menuItems" routerLinkActive="active">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
        <i class="{{ menuitem.class }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" *ngIf="menuitem.type === 'sub'" (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
        [attr.aria-expanded]="!menuitem.isCollapsed" [attr.aria-controls]="menuitem.collapse" routerLinkActive="active">
        <i class="{{ menuitem.class }}"></i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{ menuitem.collapse }}" class="collapse ml-5" *ngIf="menuitem.type === 'sub'"
        [ngbCollapse]="menuitem.isCollapsed">

        <ul class="nav">
          <li *ngFor="let childitems of menuitem.children" routerLinkActive="active">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path, childitems.path]" *ngIf="childitems.type === 'link'">
              <!-- <i class="{{ childitems.class }} mt-1"></i> -->
              <p>&#8226; {{ childitems.title }}</p>
              <!-- <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">{{ childitems.title }}</span> -->
            </a>
            <!--If it have a submenu-->
            <a data-toggle="collapse" (click)="childitems.isCollapsed = !childitems.isCollapsed"
              [attr.aria-expanded]="!childitems.isCollapsed" [attr.aria-controls]="childitems.collapse"
              *ngIf="childitems.type === 'sub'">
              <!-- <i class="{{ childitems.class }}"></i> -->
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">&#8226; {{ childitems.title }}<b class="caret"></b></span>
            </a>
            <!--Display the submenu items-->
            <div id="{{ childitems.collapse }}" class="collapse ml-1" *ngIf="childitems.type === 'sub'"
              [ngbCollapse]="childitems.isCollapsed">
              <ul class="nav">
                <li *ngFor="let childitem of childitems.children">

                  <a [routerLink]="[menuitem.path, childitems.path, childitem.path]" routerLinkActive="active">
                    <!-- <i class="{{ childitem.class }}  mt-1"></i> -->
                    <span class="sidebar-mini-icon">{{childitem.smallTitle}}</span>
                    <span class="sidebar-normal">&#8226; {{ childitem.title }}</span>
                  </a>
                </li>
              </ul>

            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>

</div>
