import { RegisterService } from './../../service/register.service';
import { CarService } from './../../service/car.service';
import { ProvinceService } from './../../service/province.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MustMatch } from '../../service/must-match.validator';
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
//Date and time picker
import { NgbDateStruct, NgbTimeStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  isLoginError : boolean = false;
  message:string;
  hide = true;
  signinForm: FormGroup;
  submitted = false;
  dataProvince:any;
  dataCarbrand: any;
  dataCarmodelMain: any;
  dataCarmodelSecond: any;
  insuranceEndDate: NgbDateStruct;
  constructor(
    public registerService: RegisterService,
    public fb: FormBuilder,
    private router : Router,
    public apiCar: CarService,
    public apiProvince:ProvinceService,
    private el : ElementRef,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { 
    this.signinForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      tel: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['',[Validators.required, Validators.minLength(6)]],
      recaptchaReactive:['', Validators.required],
      address:['',Validators.required],
      carHave:['',[Validators.required,Validators.min(1)]],
      carBrandMain:[''],
      carModelMain:[''],
      carBrandSecond:[''],
      carModelSecond:[''],
      usedToFilm:['1'],
      demand:['1'],
      insurance_end_date:[''],
      consent:['', Validators.required],
      others:['']
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    
  }

  ngOnInit() {
    //console.log(this.message);
    //this.message=this.authService.messageError;
   
    this.apiCar.getCarbrand().subscribe((data: {}) => {
      this.dataCarbrand = data;
    })
    this.apiProvince.getProvince().subscribe((data: {}) => {
      this.dataProvince = data;
    })
  }
  get f() { return this.signinForm.controls; }
  onSubmit(){
    this.submitted = true;
    if (this.signinForm.invalid) {
      this.signinForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
      return;
    } else {
      this.spinner.show();
      this.message = "";
      this.registerService.register(this.signinForm.value).subscribe((res: any) => {
        
        //console.log(res.status+' '+res.description);
        //return res;
        if(res.status=='200'){
          this.spinner.hide();
          this.router.navigate(['/login']);
          
        }else{
          this.spinner.hide();
          this.toastr.error(
            '<span class=" tim-icons icon-bell-55"></span>'+res.description,
            "",
            {
              toastClass: "alert alert-danger alert-with-icon"
            }
          );
        }

      })
    }
    
      
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
  resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
   
  }
  carbrandOnChange(carId,model) {
    this.apiCar.getCarbmodel(carId).subscribe((data: {}) => {
      if(model=="carBrandMain"){
        this.dataCarmodelMain = data;
      }else{
        this.dataCarmodelSecond = data;
      }
      
      
    })
  }
}
