import { LuckyService } from '../../../service/lucky.service';
import { Component, ElementRef, OnInit, ViewChild, TemplateRef, Input, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {Router} from "@angular/router"
//Modal
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { NgbModal,NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-lucky-wheel-check',
  templateUrl: './lucky-wheel-check.component.html',
  styleUrls: ['./lucky-wheel-check.component.scss']
})
export class LuckyWheelCheckComponent implements OnInit {

  searchForm: FormGroup;
  searched = false;
  booking_no:string;
  seed=['TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','ZEN','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','ZEN','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO PLUS','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','SEVEN','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID','TUXEDO HYBRID']
  // seed=['SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN','SEVEN']
  idToLandOn: any=0;
  checkCondition:boolean=false;
  @Input() imagePath;
  @ViewChild('rewardModal') public rewardModal: ModalDirective;
  @ViewChild('unboxModal') public unboxModal: ModalDirective;
  constructor(
    public luckyService: LuckyService,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    modalConfig: NgbModalConfig,
  ) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
   }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      booking_no: ['', Validators.required]
    });
  }

  get s() { return this.searchForm.controls; }
  vidEnded(){
    //alert("Video ended.")
    this.modalService.dismissAll(this.unboxModal);
    this.modalService.open(this.rewardModal, {
      size: "md",
      centered: true,
      backdrop: false,
      keyboard: false,
      backdropClass: "modal-backdrop"
  })
  }

  onSearch() {
    this.searched = true;
    if (this.searchForm.invalid) {
      return;
    } else {
      this.spinner.show();
      this.luckyService.get(this.searchForm.value["booking_no"]).subscribe((data: any) => {
        
        if (data.status == "200") {
          this.idToLandOn = this.seed[Math.floor(Math.random() * this.seed.length)]; 
          this.booking_no=this.searchForm.value["booking_no"];
              if(this.idToLandOn=="TUXEDO HYBRID"){
                this.imagePath="assets/imgs/TUXEDO_HYBRID.jpg";
              }else if(this.idToLandOn=="TUXEDO"){
                this.imagePath="assets/imgs/TUX.jpg";
              }else if(this.idToLandOn=="TUXEDO PLUS"){
                this.imagePath="assets/imgs/TUXP.jpg";
              }else if(this.idToLandOn=="ZEN"){
                this.imagePath="assets/imgs/ZEN.jpg";
              }else if(this.idToLandOn=="SEVEN"){
                this.imagePath="assets/imgs/SEVEN.jpg";
              }else{
                this.imagePath="assets/imgs/Umbrella.jpg";
              }
          this.luckyService.update(this.booking_no,this.idToLandOn).subscribe((data: any) => {
            
            if (data.status == "200") {
              
              this.searched = false;
              this.searchForm.reset();
              
              this.spinner.hide();
              this.modalService.open(this.rewardModal, {
                size: "md",
                centered: true,
                backdrop: false,
                keyboard: false,
                backdropClass: "modal-backdrop"
            })
              
            }else{
              this.spinner.hide();
              this.toastr.error(
                '<span class=" tim-icons icon-bell-55"></span>' + data.message,
                "",
                {
                  timeOut: 8000,
                  enableHtml: true,
                  closeButton: true,
                  toastClass: "alert alert-danger alert-with-icon",
                  positionClass: "toast-top-right"
                }
              );
            }
          })
          
         
        } else {
        
          this.spinner.hide();
          this.toastr.error(
            '<span class=" tim-icons icon-bell-55"></span>' + data.message,
            "",
            {
              timeOut: 8000,
              enableHtml: true,
              closeButton: true,
              toastClass: "alert alert-danger alert-with-icon",
              positionClass: "toast-top-right"
            }
          );
        }
      })
    }
  }
}
