<div class=" content">
  <div class=" container">
    <div class=" col-lg-8 col-md-8 ml-auto mr-auto mt-5">
      <form class=" form" [formGroup]="emailForm" (ngSubmit)="checkEmail()">
        <div class=" card card-login">
          <div class=" card-header text-center">
            <img alt="aututuxedo logo" class="card-image" src="assets/imgs/logo.png">
            <h2 class=" card-title mt-3">Forgot password</h2>
          </div>
          <div class=" card-body">
            <div *ngIf="message" class="alert alert-danger">{{message}}</div>

            <div class="input-group">
              <div class=" input-group-prepend">
                <div class=" input-group-text"><i class=" tim-icons icon-email-85"></i></div>
              </div>
              <input class=" form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
                formControlName="email" type="text" required>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">This filed is required!</div>
              </div>
            </div>
            <div class=" card-footer">
              <!--<a class=" btn btn-primary btn-lg btn-block mb-3" href="#pablo">Login</a>-->
              <button class="btn btn-gold btn-block animation-on-hover mb-3" type="submit">Submit</button>
              <!--<div class=" pull-left">
                    <h6><a class=" link footer-link" href="javascript:void(0)"> Create Account </a></h6>
                  </div>
                -->
              <div class=" pull-right">
                <p><a class="text-register" [routerLink]="['/login']">Login</a></p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>
