import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LuckyService {

  constructor(
    private http:HttpClient
  ) { }

  get(booking_no){
    return this.http.get(environment.apiURL+'luckywheel/'+booking_no)
  }
  update(booking_no,lucky_reward){
    let data={'booking_no':booking_no,'lucky_reward':lucky_reward}
    return this.http.post(environment.apiURL+'luckywheel/update',data)
  }
}
