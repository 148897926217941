import { Component } from "@angular/core";
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "black-dashboard-angular";
  constructor(
    private userIdle: UserIdleService,
    private router: Router,) {}
 
  ngOnInit() {

    this.userIdle.resetTimer();
    //Start watching for user inactivity.
    this.userIdle.startWatching();
      
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => count);
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      localStorage.clear();
      //this.userIdle.stopWatching();
      this.userIdle.resetTimer();
      this.router.navigate(['/login']);
    })
  }
}
