import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AddMemberModel, MemberModel } from './model';
import { Observable } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  //apiURL = "http://auto-api.tdeca.com/api/";
  constructor(public authService: AuthService, public jwtHelper: JwtHelperService, private http: HttpClient) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  getMember(data): Observable<MemberModel> {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    //console.log(user);
    return this.http.get<MemberModel>(environment.apiURL + 'member/' + data)
  }
  addMember(data) {
    //console.log(data);
    return this.http.post(environment.apiURL + 'member', data)
  }

}
