import { LuckyWheelComponent } from './pages/lucky-wheel/lucky-wheel.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { LoginComponent } from './pages/login/login.component';
import {AddAgentComponent} from './pages/admin/add-agent/add-agent.component';
import {AddMemberComponent} from './pages/admin/add-member/add-member.component';
import {AddCarComponent} from './pages/admin/add-car/add-car.component';
import {ProfileComponent} from './pages/admin/profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import {RegisterComponent} from './pages/register/register.component';
import {LuckyWheelCheckComponent} from './pages/lucky-wheel/lucky-wheel-check/lucky-wheel-check.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
 /* { path: '', redirectTo: '/home', canActivate: [AuthGuard], pathMatch: 'full' },*/
 { path: 'login', component: LoginComponent, pathMatch: 'full'},
 { path: 'register', component: RegisterComponent, pathMatch: 'full'},
 { path: 'forget-password', component: ForgotPasswordComponent, pathMatch: 'full'},
 
  /*{ path: '', redirectTo: "/login", pathMatch: "full"},
  { path: '', redirectTo: "/home", pathMatch: "full"},*/
  /*{ 
    path: 'admin', 
    component: AdminLayoutComponent, 
    children: [
     {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule"
      },
     
    ]
   
  },
  {
    path: "profile",
    component: ProfileComponent
  },
  */
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        canActivateChild: [AuthGuard],
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule"
      },
     
    ]
  }, 
  {
    path: 'manager',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        canActivateChild: [AuthGuard],
        loadChildren:
          "./layouts/admin-layout/manager-layout.module#ManagerLayoutModule"
      },
     
    ]
  },
  {
    path: 'agent',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        canActivateChild: [AuthGuard],
        loadChildren:
          "./layouts/admin-layout/agent-layout.module#AgentLayoutModule"
      },
     
    ]
  },
  {
    path: 'member',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        canActivateChild: [AuthGuard],
        loadChildren:
          "./layouts/admin-layout/member-layout.module#MemberLayoutModule"
      },
     
    ]
  },
  {
    path: "mysterybox",
    component: LuckyWheelCheckComponent, 
    pathMatch: 'full'
  },
  // {
  //   path: "mysterybox",
  //   component: LuckyWheelComponent, 
  //   pathMatch: 'full'
  // },
  {
    path: "**",
    redirectTo: "/login" , pathMatch: "full"
  },
  /*,
  {
    path: "**",
    redirectTo: "/home" , pathMatch: "full", canActivate: [AuthGuard]
  }*/
  

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule],
  providers:[
    AuthGuard
  ]
})
export class AppRoutingModule {}
