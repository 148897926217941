<div class=" content">
  <div class=" container">
    <div class=" col-lg-8 col-md-8 ml-auto mr-auto mt-5">
      <form class=" form" [formGroup]="signinForm" (ngSubmit)="loginUser()">
        <div class=" card card-login">
          <div class=" card-header text-center">
            <img alt="aututuxedo logo" class="card-image"src="assets/imgs/logo.png">
            <h2 class=" card-title mt-3">เข้าสู่ระบบ</h2>
          </div>
          <div class=" card-body">
            <div *ngIf="message" class="alert alert-danger">{{message}}</div>

            <div class="input-group">
              <div class=" input-group-prepend">
                <div class=" input-group-text"><i class=" tim-icons icon-single-02"></i></div>
              </div>
              <input class=" form-control" [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" placeholder="เบอร์มือถือ" formControlName="tel" type="text" required>
              <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                <div *ngIf="f.tel.errors.required">This filed is required!</div>
              </div>
            </div>
            <div class="input-group">
              <div class=" input-group-prepend">
                <div class=" input-group-text"><i class=" tim-icons icon-lock-circle"></i></div>
              </div>
              
              <input class="password form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="รหัสผ่าน" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" required #password>
              <div class=" input-group-prepend">
                <span class="input-group-text">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }"
                    (click)="toggleFieldTextType()"
                  ></i>
                </span>
              </div>
              
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">This filed is required!</div>
              </div>
            </div>
            
            <button class="btn btn-gold animation-on-hover mt-4 ml-auto mr-auto mb-3" type="submit">เข้าสู่ระบบ</button>
          </div>
          
          <div class=" card-footer">
            <!--<a class=" btn btn-primary btn-lg btn-block mb-3" href="#pablo">Login</a>-->
            
            <div class=" pull-left">
                
            <p>ยังไม่มีบัญชี? <a href="register" class="text-register">ลงทะเบียนได้ทางนี้</a></p>
              </div>
              <div class=" pull-right">
                <p><a class="text-register" [routerLink]="['/forget-password']"> Forgot password? </a></p>
              </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>