import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
  
})

export class LoginComponent implements OnInit {
  @ViewChild('password') el: ElementRef;
  isLoginError : boolean = false;
  message:string;
  hide = true;
  signinForm: FormGroup;
  submitted = false;
  fieldTextType: boolean;
  constructor(
    public authService: AuthService,
    public fb: FormBuilder,
    private router : Router,
    //private el : ElementRef,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { 
    this.signinForm = this.fb.group({
      tel: ['', Validators.required],
      password: ['', Validators.required]
    })
    
  }

  ngOnInit() {
    //console.log(this.message);
    //this.message=this.authService.messageError;
    if (this.authService.isLoggedIn == true) {
      //window.alert("Access not allowed!");
      if(this.authService.getUserLevel()=='admin'){
        this.router.navigate(['/admin/home']);
      }else if(this.authService.getUserLevel()=='agent'){
        this.router.navigate(['/agent/home']);
      }else if(this.authService.getUserLevel()=='member'){
        this.router.navigate(['/member/home']);
      }else if(this.authService.getUserLevel()=='manager'){
        this.router.navigate(['/manager/home']);
      }
    }
  }
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}
  get f() { return this.signinForm.controls; }
  loginUser(){
    this.submitted = true;
    if (this.signinForm.invalid) {
      this.signinForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
      return;
    } else {
      this.spinner.show();
      this.message = "";
      this.authService.userAuthentication(this.signinForm.value).subscribe((res: any) => {
        localStorage.setItem('access_token', res.token);
        localStorage.setItem('user_level', res.level);
        localStorage.setItem('shop_name', res.shop_name);
        localStorage.setItem(this.authService.tokenKey, res.token);
        //console.log(res.status+' '+res.description);
        //return res;
        if(res.status=='200'){
          this.spinner.hide();
          if(this.authService.getUserLevel()=='admin'){
            this.router.navigate(['/admin/home']);
          }else if(this.authService.getUserLevel()=='agent'){
            this.router.navigate(['/agent/home']);
          }else if(this.authService.getUserLevel()=='manager'){
            this.router.navigate(['/manager/home']);
          }else if(this.authService.getUserLevel()=='member'){
            this.router.navigate(['/member/home']);
          }
        }else{
          // this.snotifyService.error(res.description, 'ผิดพลาด', {
          //   timeout: 6000,
          //   showProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true
          // });
          this.spinner.hide();
          this.toastr.error(
            '<span class=" tim-icons icon-bell-55"></span>'+res.description,
            "",
            {
              timeOut: 8000,
              enableHtml: true,
              closeButton: true,
              progressBar:true,
              toastClass: "alert alert-danger alert-with-icon",
              positionClass: "toast-top-right"
            }
          );
        }

      })
    }
    
      
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
}