import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {ProfileModel} from './model';
import {Observable} from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  //apiURL="http://auto-api.tdeca.com/api/";
  constructor(public authService: AuthService,public jwtHelper: JwtHelperService,private http:HttpClient) {}
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  getProfile(data):Observable<ProfileModel>{
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    return this.http.get<any>(environment.apiURL+'profile/'+data)
  }
  editUser(data){
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    //console.log( data);
    return this.http.post(environment.apiURL+'profile/update',data)
  }
  changePassword(data){
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    data.tel=this.authService.getUser();
    
    return this.http.post(environment.apiURL+'profile/password/update',data)
  }
}
