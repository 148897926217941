import { Component, OnInit } from "@angular/core";
import { AuthService } from '../../auth/auth.service';
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  type?: string;
  collapse?: string;
  isCollapsed?: boolean;
  children?: ChildrenRoute[];
}
export interface ChildrenRoute {
  path: string;
  title: string;
  smallTitle?: string;
  icon: string;
  class: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path: string;
  title: string;
  icon: string;
  class: string;
  type?: string;
}
export const AdminROUTES: RouteInfo[] = [
 
  
  {
    path: "home",
    title: "Home",
    icon: "",
    class: "fas fa-home",
    type: "link"
  },
  {
    path: "dashboard",
    title: "Dashboard",
    icon: "",
    class: "fas fa-tachometer-alt",
    type: "sub",
    collapse: "dashboard",
    isCollapsed: true,
    children: [
      {
        path: "installation",
        title: "การติดตั้ง",
        icon: "",
        class: "fab fa-instalod",
        type: "sub",
        collapse: "installation",
        isCollapsed: true,
        children: [
          {
            path: "amount",
            title: "ยอดติดตั้ง",
            icon: "",
            class: "fas fa-sort-amount-up-alt",
            type: "link"
          },
          {
            path: "performance",
            title: "ประสิทธิภาพ",
            icon: "",
            class: "far fa-star",
            type: "link"
          },
          {
            path: "quality",
            title: "คุณภาพ",
            icon: "",
            class: "far fa-smile",
            type: "link"
          },
        ]
      }
    ]
    
  },
  {
    path: "calendar",
    title: "Calendar",
    icon: "",
    class: "far fa-calendar-alt",
    type: "link"
  },
  {
    path: "agent",
    title: "Agent",
    icon: "",
    class: "fas fas fa-user",
    type: "sub",
    collapse: "agent",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-plus",
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fas fa-user",
        type: "link"
      },
    ]
  },
  {
    path: "member",
    title: "Member",
    icon: "",
    class: "fas fa-users",
    type: "sub",
    collapse: "member",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-user-plus" ,
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-users",
        type: "link"
      },
    ]
  },
  {
    path: "car",
    title: "Car",
    icon: "",
    class: "fas fa-car",
    type: "sub",
    collapse: "car",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-plus",
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "search",
        title: "Search",
        smallTitle: "Search",
        icon: "",
        class: "fas fa-search",
        type: "link"
      },
    ]
  },
  {
    path: "coins",
    title: "Coins",
    icon: "",
    class: "fab fa-bitcoin",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "redeem",
        title: "Redeem",
        smallTitle: "Redeem",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
      // {
      //   path: "approve",
      //   title: "Approving",
      //   smallTitle: "Approving",
      //   icon: "",
      //   class: "fas fa-car",
      //   type: "link"
      // },
      {
        path: "history",
        title: "History",
        smallTitle: "History",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },
  {
    path: "report",
    title: "Report",
    icon: "",
    class: "far fa-file-alt",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "car",
        title: "Car",
        smallTitle: "Car",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
      {
        path: "install",
        title: "Install",
        smallTitle: "Install",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },
  {
    path: "setting",
    title: "Setting",
    icon: "",
    class: "fas fa-cog",
    type: "sub",
    collapse: "setting",
    isCollapsed: true,
    children: [
      {
        path: "installer",
        title: "ตั้งค่าช่าง",
        smallTitle: "Installer",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "installcenter",
        title: "ตั้งค่าสถานที่ติดตั้ง",
        smallTitle: "IC",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "car",
        title: "ตั้งค่ารถ",
        smallTitle: "Car",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
    ]
  },
];
export const ManagerROUTES: RouteInfo[] = [
 
  {
    path: "home",
    title: "Home",
    icon: "",
    class: "fas fa-home",
    type: "link"
  },
  {
    path: "dashboard",
    title: "Dashboard",
    icon: "",
    class: "fas fa-tachometer-alt",
    type: "sub",
    collapse: "dashboard",
    isCollapsed: true,
    children: [
      {
        path: "installation",
        title: "การติดตั้ง",
        icon: "",
        class: "fab fa-instalod",
        type: "sub",
        collapse: "installation",
        isCollapsed: true,
        children: [
          {
            path: "amount",
            title: "ยอดติดตั้ง",
            icon: "",
            class: "fas fa-sort-amount-up-alt",
            type: "link"
          },
          {
            path: "performance",
            title: "ประสิทธิภาพ",
            icon: "",
            class: "far fa-star",
            type: "link"
          },
          {
            path: "quality",
            title: "คุณภาพ",
            icon: "",
            class: "far fa-smile",
            type: "link"
          },
        ]
      }
    ]
    
  },
  {
    path: "calendar",
    title: "Calendar",
    icon: "",
    class: "far fa-calendar-alt",
    type: "link"
  },
  {
    path: "member",
    title: "Member",
    icon: "",
    class: "fas fa-users",
    type: "sub",
    collapse: "member",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-user-plus" ,
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-users",
        type: "link"
      },
    ]
  },
  {
    path: "car",
    title: "Car",
    icon: "",
    class: "fas fa-car",
    type: "sub",
    collapse: "car",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "search",
        title: "Search",
        smallTitle: "Search",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
      // {
      //   path: "report",
      //   title: "Report",
      //   smallTitle: "Report",
      //   icon: "",
      //   class: "fas fa-cogs",
      //   type: "link"
      // },
    ]
  },
  {
    path: "coins",
    title: "Coins",
    icon: "",
    class: "fab fa-bitcoin",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "history",
        title: "History",
        smallTitle: "History",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },{
    path: "report",
    title: "Report",
    icon: "",
    class: "far fa-file-alt",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "car",
        title: "Car",
        smallTitle: "Car",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
      {
        path: "install",
        title: "Install",
        smallTitle: "Install",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },
 ];
export const AgentROUTES: RouteInfo[] = [ 
  {
    path: "home",
    title: "Home",
    icon: "",
    class: "fas fa-home",
    type: "link"
  },
  {
    path: "member",
    title: "Member",
    icon: "",
    class: "fas fa-users",
    type: "sub",
    collapse: "member",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-user-plus" ,
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-users",
        type: "link"
      },
    ]
  },
  {
    path: "car",
    title: "Car",
    icon: "",
    class: "fas fa-car",
    type: "sub",
    collapse: "car",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
    ]
  },
  {
    path: "coins",
    title: "Coins",
    icon: "",
    class: "fab fa-bitcoin",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "redeem",
        title: "Redeem",
        smallTitle: "Redeem",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      },
      {
        path: "history",
        title: "History",
        smallTitle: "History",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },{
    path: "report",
    title: "Report",
    icon: "",
    class: "far fa-file-alt",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      {
        path: "car",
        title: "Car",
        smallTitle: "Car",
        icon: "",
        class: "fas fa-cogs",
        type: "link"
      }
      
    ]
  },
 ];
 export const MemberROUTES: RouteInfo[] = [
  {
    path: "home",
    title: "Home",
    icon: "",
    class: "fas fa-home",
    type: "link"
  },
  
  {
    path: "car",
    title: "Car",
    icon: "",
    class: "fas fa-car",
    type: "sub",
    collapse: "car",
    isCollapsed: true,
    children: [
      {
        path: "add",
        title: "Add",
        smallTitle: "Add",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      {
        path: "show",
        title: "Show",
        smallTitle: "Show",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
    ]
  },
  {
    path: "coins",
    title: "Coins",
    icon: "",
    class: "fab fa-bitcoin",
    type: "sub",
    collapse: "coins",
    isCollapsed: true,
    children: [
      // {
      //   path: "redeem",
      //   title: "Redeem",
      //   smallTitle: "Redeem",
      //   icon: "",
      //   class: "fas fa-cogs",
      //   type: "link"
      // },
      
      {
        path: "history",
        title: "History",
        smallTitle: "History",
        icon: "",
        class: "fas fa-car",
        type: "link"
      },
      
    ]
  },
 ];
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(public authService:AuthService) {}

  ngOnInit() {
    
    
    
    if(this.authService.getUserLevel()=='admin'){
      this.menuItems = AdminROUTES.filter(menuItem => menuItem);
    }else if(this.authService.getUserLevel()=='agent'){
      this.menuItems = AgentROUTES.filter(menuItem => menuItem);
    }else if(this.authService.getUserLevel()=='member'){
      this.menuItems = MemberROUTES.filter(menuItem => menuItem);
    }else if(this.authService.getUserLevel()=='manager'){
      this.menuItems = ManagerROUTES.filter(menuItem => menuItem);
    }
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

}
