import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {CarModel} from './model';
import {Observable} from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { environment } from '../../environments/environment';
declare const Pusher: any;
@Injectable({
  providedIn: 'root'
})

export class CarService {
  pusher: any;
  channel: any;
  //apiURL="http://auto-api.tdeca.com/api/";
  constructor(public authService: AuthService,public jwtHelper: JwtHelperService,private http:HttpClient) {
    // this.pusher = new Pusher(environment.pusher.key, {
    //   cluster: environment.pusher.cluster,
    //   encrypted: true
    // });
    // this.channel = this.pusher.subscribe('my-channel');
  }
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  getCar(data):Observable<CarModel>{
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    return this.http.get<CarModel>(environment.apiURL+'car/'+data)
  }
  exportCar(data):Observable<CarModel>{
    //console.log(user);
    data.startDate = data.startDate['year'] + '-' + data.startDate['month'] + '-' + data.startDate['day'];
    data.endDate = data.endDate['year'] + '-' + data.endDate['month'] + '-' + data.endDate['day'];
    return this.http.post<CarModel>(environment.apiURL+'report/install',data)
  }
  exportInstaller(data):Observable<CarModel>{
    //console.log(user);
    data.startDate = data.startDate['year'] + '-' + data.startDate['month'] + '-' + data.startDate['day'];
    data.endDate = data.endDate['year'] + '-' + data.endDate['month'] + '-' + data.endDate['day'];
    return this.http.post<CarModel>(environment.apiURL+'report/installer',data)
  }
  addCar(data){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    
    try {
      if (data.booking_date['year'] == null||data.booking_date['year']==undefined) {
        data.booking_date = null;

      } else {
        data.booking_date = data.booking_date['year'] + '-' + data.booking_date['month'] + '-' + data.booking_date['day'];
      }
    }
    catch (e) {

      data.install_date = null;
    }
    try {
      if (data.install_date['year'] == null||data.install_date['year']==undefined) {
        data.install_time = null;

      } else {
        data.install_date = data.install_date['year'] + '-' + data.install_date['month'] + '-' + data.install_date['day'];
      }
    }
    catch (e) {

      data.install_date = null;
    }
    try {
      if (data.install_time['hour'] == null) {
        data.install_time = null;
      } else {
        data.install_time = data.install_time['hour'] + ':' + data.install_time['minute'];
      }
    } catch (e) {

      data.install_time = null;
      //console.log(e+data.timeInstall);
    }
   // data.owner_tel=this.authService.getUser();
   //console.log(data);
    return this.http.post(environment.apiURL+'car',data)
  }
  getCarbrand(){
    return this.http.get(environment.apiURL+'carbrand')
  }
  getCarbmodel(carId){
    return this.http.get(environment.apiURL+'carmodel/'+carId)
  }
  addCarName(data){
    return this.http.post(environment.apiURL+'addcar', data)
  }
}
