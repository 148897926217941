import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {HistoryModel} from './model';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  //apiURL="http://auto-api.tdeca.com/api/";
  constructor(public jwtHelper: JwtHelperService,private http:HttpClient) {}
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  getHistory(data):Observable<HistoryModel>{
    var reqHeader = new HttpHeaders({ 'Content-Type':'application/json; charset=utf-8' });
    //console.log(user);
    return this.http.get<HistoryModel>(environment.apiURL+'history/'+data)
  }
}
