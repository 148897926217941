import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {CarModel} from './model';
import {Observable} from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { environment } from '../../environments/environment';
declare const Pusher: any;
@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  pusher: any;
  channel: any;
  //apiURL="http://auto-api.tdeca.com/api/";
  constructor(public authService: AuthService,public jwtHelper: JwtHelperService,private http:HttpClient) {
    // this.pusher = new Pusher(environment.pusher.key, {
    //   cluster: environment.pusher.cluster,
    //   encrypted: true
    // });
    // this.channel = this.pusher.subscribe('my-channel');
  }
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  
  getProvince(){
    return this.http.get(environment.apiURL+'province')
  }
  
}
