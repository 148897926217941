import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http:HttpClient) { }
  register(data){
    try {
      if (data.insurance_end_date['year'] == null) {
        data.insurance_end_date = null;

      } else {
        data.insurance_end_date = data.insurance_end_date['year'] + '-' + data.insurance_end_date['month'] + '-' + data.insurance_end_date['day'];
      }
    }
    catch (e) {

      data.insurance_end_date = null;
    }
    return this.http.post(environment.apiURL+'register',data)
  }
}
